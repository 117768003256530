import "./../../styles/pages/upgrade/index.less"

// 使用问题反馈
const FeedBack = () => {
    return (
        <div className="dilatation">
            <p className="upgrade-text">如您在使用过程中，有任何问题和建议，欢迎登陆GitHub提出您的宝贵意见！</p>
        </div>
    )
}
export default FeedBack