import { HashRouter as Router } from "react-router-dom"
import HeaderLayout from "./components/Header";
import RouterView from "./router/index"

function App() {
  return (
    <Router>
      <HeaderLayout />
      <RouterView />
    </Router>
  );
}

export default App;
