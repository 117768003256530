import "./../../../styles/pages/login/term.less"
export const TermsContainer = () => {
    return <div className="doc-container">
        <h1>软件使用协议</h1>
        <p><br /></p>
        <h2>定义</h2>
        <p>1.焱融科技：指北京焱融科技有限公司</p>
        <p>2.用户：是指通过焱融科技提供的获取软件授权和帐号注册途径获得软件产品及号码授权许可以及使用焱融科技相关服务的个人或组织。</p>
        <p>3.软件：是指YRCloudFile GitHub社区版软件，包含集群概览、目录导出功能，支持NFS，Linux客户端、Windows客户端、Kubernetes CSI挂载方式。</p>
        <p>4.《使用协议》：指《软件使用协议》，是用户与焱融科技之间的关于下载、安装、使用、复制YRCloudFile GitHub社区版软件；注册、使用、管理焱融科技帐号；以及使用焱融科技相关服务所订立的协议。</p>
        <p>5. 账号应由自然人创建，由“机器人”注册的账号是不允许的。</p>
        <p><br /></p>
        <h2>重要须知</h2>
        <p>焱融科技在此特别提醒您（下称“用户”）认真阅读、充分理解本《使用协议》--- 本《使用协议》描述焱融科技与用户之间关于“软件”使用及服务相关方面的权利义务。请审慎阅读并选择接受或不接受本《使用协议》。除非用户接受本《使用协议》所有条款，否则无权下载、安装或使用本软件及其相关服务。用户下载、安装、使用、帐号获取和登录等行为均将视为对本《使用协议》的接受，并同意接受本《使用协议》各项条款的约束。
            本《使用协议》可由焱融科技随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知。用户可重新下载安装本软件或网站查阅最新版协议条款。在焱融科技修改《使用协议》条款后，如果用户不接受修改后的条款，请立即停止使用焱融科技提供的软件和服务，用户继续使用焱融科技提供的软件和服务将被视为已接受了修改后的协议。</p>
        <p><br /></p>
        <h2>1.知识产权声明</h2>
        <p>1.1 本“软件”是由焱融科技开发。“软件”的一切版权、商标权、专利权、商业秘密等知识产权及其邻接权，以及与“软件”相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料、或电子文档等均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应国际条约及相关法律法规的保护，除涉及第三方授权的软件或技术外，上述知识产权归焱融科技所有。用户的下载使用登录账号等行为不视为将焱融科技任何现有的或将来的相关的知识产权转让给了用户。</p>
        <p>1.2 未经焱融科技书面同意，用户不得为任何营利性或非营利性的目的自行实施、利用、转让或许可任何第三方实施、利用上述知识产权，焱融科技保留追究上述未经许可行为的权利。</p>
        <p><br /></p>
        <h2>2.焱融科技帐号</h2>
        <p>要使用本“软件”，用户需填写并提交个人或组织资料注册焱融科技帐号。作为帐号注册过程的一部分，用户必须同意：(1) 按照帐号注册过程的提示信息，提供有关您本人的真实信息（此类信息必须保持最新、完整且准确）；(2) 根据需要维护并更新注册人信息，使之始终保持最新、完整且准确。</p>
        <p>2.1 用户可以通过注册焱融科技帐号使用焱融科技提供的各种服务。服务包括但不限于云舟、GitHub社区版等，焱融科技保留对本公司未来服务改变和说明的权利。无论用户通过何种方式获得焱融科技帐号，均受本协议约束。用户通过焱融科技帐号使用焱融科技的服务时，须同时遵守各项条款。</p>
        <p>2.2 焱融科技帐号的所有权归焱融科技所有，用户完成申请注册手续后，获得焱融科技帐号的使用权。</p>
        <p>2.3 焱融科技帐号使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。如果焱融科技发现使用者并非帐号初始注册人，焱融科技有权在未经通知的情况下回收该帐号且无需向该帐号使用人承担法律责任，由此带来的包括并不限于用户通讯中断、用户资料清空等损失由用户自行承担。焱融科技禁止用户私下有偿或无偿转让帐号，以免因帐号问题产生纠纷，用户应当自行承担因违反此要求而遭致的所有损失，同时焱融科技保留追究上述行为人法律责任的权利。</p>
        <p>2.4 用户承担焱融科技帐号与密码的保管责任，并就其帐号及密码项下之一切活动负全部责任。用户须重视焱融科技帐号密码和公开邮箱的密码保护。用户同意如发现他人未经许可使用其帐号时应立即通知焱融科技。</p>
        <p>2.5 用户焱融科技帐号在丢失或遗忘密码后，须遵照焱融科技的申诉途径及时申诉请求找回帐号。用户应提供能增加帐号安全性的个人密码保护资料。用户可以凭初始注册资料及个人密码保护资料填写申诉向焱融科技申请找回帐号，焱融科技的密码找回机制仅负责识别申诉单上所填资料与系统记录资料的正确性，无法识别申诉人是否系真正帐号使用人。对用户因被他人冒名申诉而致的损失，焱融科技不承担任何责任，用户知晓焱融科技帐号及密码保管责任在于用户，焱融科技并不承诺焱融科技帐号丢失或遗忘密码后用户一定能通过申诉找回帐号。</p>
        <p>2.6 用户注册焱融科技帐号后如果长期不使用，焱融科技有权回收该帐号，以免造成资源浪费，由此带来的包括但不限于用户通信中断、用户资料、邮件等损失由用户自行承担。</p>
        <p><br /></p>
        <h2>3.“软件”使用</h2>
        <p>本平台保留对以下各项内容、信息完全的、不可分割的所有权及知识产权：</p>
        <p>1. 除用户自行导入、上传的内容外，本平台拥有平台相关的所有元素，包括但不限于所有内容、数据、技术、软件、代码、用户界面以及与其相关的任何衍生作品；</p>
        <p>2. 平台帐号的所有权归平台所有，用户完成申请注册手续后，仅获得本平台帐号的使用权，且该使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖本平台帐号或者以其他方式许可非初始申请注册人使用本平台帐号。非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用本平台帐号。</p>
        <p><br /></p>
        <h2>【通用条款】</h2>
        <p>用户在遵守法律及本《使用协议》的前提下可依本《使用协议》使用本”软件”。用户无权实施包括但不限于下列行为：</p>
        <p>3.1 对本“软件”进行反向工程、反向汇编、反向编译等；</p>
        <p>3.2 对于本“软件”相关信息等，未经焱融科技书面同意，用户擅自实施包括但不限于下列行为：使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、擅自借助本软件发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等；</p>
        <p>3.3 利用本“软件”发表、传送、传播、储存违反国家法律、危害国家安全、祖国统一、社会稳定、公序良俗的内容，或任何不当的、侮辱诽谤的、淫秽的、暴力的及任何违反国家法律法规政策的内容；</p>
        <p>3.4 利用本“软件”发表、传送、传播、储存侵害他人知识产权、商业秘密权等合法权利的内容；</p>
        <p>3.5 在未经焱融科技公司书面明确授权前提下，禁止从出售、出租、出借、散布、转移或转授权软件和服务或相关的链接或从使用软件和服务或软件和服务的条款中获利；</p>
        <p>3.6 其他以任何不合法的方式、为任何不合法的目的、或以任何与本协议不一致的方式使用本软件和和焱融科技提供的其他服务；</p>
        <p>3.7 用户若违反上述规定，焱融科技有权采取措施终止、全部或部分中止、限制用户帐号使用。</p>
        <p>3.8 使用本“软件”必须遵守国家有关法律和政策等，维护国家利益，保护国家安全，并遵守本《使用协议》。对于用户违法或违反本《使用协议》的使用而引起的一切责任，由用户负全部责任，与焱融科技无关；导致焱融科技损失的，焱融科技有权要求用户赔偿全部损失，并有权保留相关记录。对于用户违法或违反本《使用协议》以及违反了利用本软件和焱融科技帐号访问的焱融科技的其他服务条款，焱融科技有权视用户的行为性质，在不事先通知用户的情况下，采取包括但不限于中断使用许可、停止提供服务、限制使用、回收用户焱融科技帐号、法律追究等措施。对利用焱融科技帐号进行违法活动、骚扰、欺骗其他用户等行为，焱融科技有权回收其帐号。由此带来的包括并不限于用户通信中断、用户资料、邮件和游戏道具丢失等损失由用户自行承担。</p>
        <p>3.9 非经焱融科技或焱融科技授权开发并正式发布的其它任何由本“软件”衍生的软件均属非法，下载、安装、使用此类软件，将可能导致不可预知的风险，由此产生的一切法律责任与纠纷一概与焱融科技无关。用户不得轻易下载、安装、使用此类软件，否则，焱融科技有权在不事先通知用户的情况下单方面终止用户焱融科技帐号的使用资格。</p>
        <p>3.10 用户同意个人隐私信息是指那些能够对用户进行个人辨识或涉及个人通信的信息，包括但不限于下列信息：用户的姓名，身份证号，手机号码，IP地址，电子邮件地址信息等。而非个人隐私信息是指用户对本软件的操作状态以及使用习惯等一些明确且客观反映在焱融科技服务器端的基本记录信息和其他一切个人隐私信息范围外的普通信息。尊重用户个人隐私信息的私有性是焱融科技的一贯制度，焱融科技将会采取合理的措施保护用户的个人隐私信息，除法律或有法律赋予权限的政府部门要求或用户同意等原因外，焱融科技未经用户同意不向除合作单位以外的第三方公开、 透露用户个人隐私信息。 但是用户在注册时选择或同意，或用户与焱融科技之间就用户个人隐私信息公开或使用另有约定的除外，同时用户应自行承担因此可能产生的任何风险，焱融科技对此不予负责。同时为了运营和改善焱融科技的技术和服务，我们将可能会自己收集使用或向第三方提供用户的非个人隐私信息，这将有助于向用户提供更好的用户体验和提高我们的服务质量。</p>
        <p>3.11 “用户同意”的方式有：（1）通过点击“用户同意”或者其他方式接受本《使用协议》及焱融科技发布的其他服务条款；（2）本《使用协议》或其他服务声明中有“默认同意”条款，用户对此无异议的。</p>
        <p>3.12 焱融科技保留在任何时候根据适用法律、法规、法律程序或政府要求的需要而披露任何信息，或由焱融科技自主决定全部或部分地编辑、拒绝张贴或删除任何信息或资料的权利。</p>
        <p><br /></p>
        <h2>4.法律责任与不可抗力</h2>
        <p>4.1 焱融科技特别提请用户注意：焱融科技为了保障公司业务发展和调整的自主权，焱融科技拥有随时自行修改或中断软件授权而不需通知用户的权利，如有必要，修改或中断会以通告形式公布于焱融科技网站重要页面上。</p>
        <p>4.2 使用本“软件”由用户自己承担风险，焱融科技对本“软件”不作任何类型的担保，不论是明示的、默示的或法令的保证和条件，包括但不限于本“软件”的适销性、适用性、无病毒、无疏忽或无技术瑕疵问题、所有权和无侵权的明示或默示担保和条件，对在任何情况下因使用或不能使用本软件所产生的直接、间接、偶然、特殊及后续的损害及风险，焱融科技不承担任何责任。</p>
        <p>4.3 不可抗力，指双方缔结本协议时所不能预见、并且它的发生及其后果是不能克服和不能避免的客观情况，包括但不限于：（i）政府或政党行为如政府当局或执政党颁布的政策、法律、法规和采取新的行为措施导致不能履行；（ii）黑客攻击、计算机病毒、电信部门技术调整导致之影响、因政府管制而造成的暂时性关闭等在内的任何影响网络正常经营情形；（iii）疫情； (iv）技术故障等。因不可抗力影响到服务的正常运行的，用户因此而遭受的一切损失，焱融科技不承担责任。</p>
        <p><br /></p>
        <h2>5.其他条款</h2>
        <p>5.1 协议的完整性：本《使用协议》和网站其他许可条款，共同构成了本“软件”及其支持服务的完整协议。</p>
        <p>5.2 本《使用协议》所定的任何条款的部分或全部无效者，不影响其它条款的效力。</p>
        <p>5.3 本《使用协议》的所有标题仅仅是为了醒目及阅读方便，本身并无实际涵义，不能作为本《使用协议》涵义解释之依据。</p>
        <p>本《使用协议》版权由焱融科技所有，焱融科技保留一切解释权利。本文中提及的软件和服务名称均为焱融科技的注册商标或商标，受法律保护。</p>
        <p>版权所有 2022 焱融科技, Inc. 保留所有权利。</p>
    </div>
}
