import { useNavigate, useLocation } from "react-router-dom"
import logo from "./../../assets/imgs/img_logo_black_100.svg"
import "./index.less"

const HeaderLayout = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const freeEmail = sessionStorage.getItem('freeEmail')
    // 社区跳转
    // const localGitHub = () => {
    //     window.open('https://github.com/yr-tech/yunzhou', '_blank')
    // }
    return (
        <div className="header">
            <div className="header-top">
                <div className="header-top-left">
                    <img src={logo} onClick={() => navigate('/')} alt="炎融科技" />
                    {/* <span onClick={localGitHub}>社区</span> */}
                </div>
                {
                    location.pathname === "/activation" ?
                        <div className="header-top-right">
                            <span>已有账号？</span>
                            <b onClick={() => navigate('/login')}>立即登录</b>
                        </div>
                        :
                        location.pathname !== "/login" ?
                            <div className="header-top-right">
                                {freeEmail ?
                                    <span>{freeEmail}</span>
                                    :
                                    <b onClick={() => navigate('/login')}>立即登录</b>
                                }
                            </div>
                            :
                            null
                }

            </div>

        </div>
    )
}
export default HeaderLayout