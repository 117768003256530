import HeaderTitle from "../../components/HeaderTitle"
import "./../../styles/pages/upgrade/index.less"

// 扩容
const Dilatation = () => {
    return (
        <div className="dilatation">
            <HeaderTitle/>
            <p className="upgrade-text">YRCloudFileGitHub社区版不支持存储扩容，如需扩容服务，请先升级至企业版。</p>
        </div>
    )
}
export default Dilatation