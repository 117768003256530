
// 存储架构说明
const StoreExplain = () => {
    return (
        <div className="architrave">
            <div className="architrave-box">
                <p>YRCloudFile由4个角色组成：集群管理角色（MS），元数据管理（MDS），数据管理（OSS）和客户端（Client）。</p>
                <div className="architrave-tab">
                    <ul>
                        <li>MS是集群安装的第一个角色，MS由三节点组成集群。</li>
                        <li>MDS包含文件的元数据信息，MDS建议采用SSD作为存储介质。 </li>
                        <li>Storage是存储文件的服务进程，可以有一个或者多个RAID组成。</li>
                        <li>Client是一个内核模块，所有需要访问YRCloudFile集群的主机都需要安装client。</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default StoreExplain