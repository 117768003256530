import { useRef } from "react"
import { copyHander } from './../../utils'
import img10 from "./../../assets/imgs/src10.png"
import img11 from "./../../assets/imgs/src11.png"
import img12 from "./../../assets/imgs/src12.png"
import img13 from "./../../assets/imgs/src13.png"
import copyIcon from "./../../assets/imgs/icon_copy_16.svg"
import "./../../styles/pages/instaliClient/index.less"

interface RefObject<T> {
    readonly current: T | null
}
// 安装及配置客户端
const NFCConfig = () => {
    const copy1: RefObject<HTMLDivElement> = useRef(null)
    const copy2: RefObject<HTMLDivElement> = useRef(null)
    const copy3: RefObject<HTMLDivElement> = useRef(null)

    return (
        <div className="config-client">
            <div className="config-client-box">
                <div className="client-title">
                    <p className="client-title-h1">NFS配置文档</p>
                    <p className="client-title-h2">配置负载均衡</p>
                    <img className="src-img" src={img10} alt="" />
                    <img className="src-img" src={img11} alt="" />
                    <p className="client-title-h2">创建共享目录</p>
                    <img className="src-img" src={img12} alt="" />
                    <img className="src-img" src={img13} alt="" />
                    <p className="client-title-h2">客户端设置DNS解析</p>
                    <div className="client-text-1">
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy3}>vi /etc/resolv.conf</span>
                            <img onClick={() => { copyHander(copy3) }} src={copyIcon} alt="" />
                        </div>
                        <span>nameserver xx.xx.xx.xx</span>
                        <span>注：在/etc/resolv.conf 文件中加入 nameserver IP地址 。IP地址为步骤1中，DNS的两个IP地址</span>
                    </div>
                    <p className="client-title-h2">挂载文件系统</p>
                    <div className="client-text-1">
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy1}>mkdir /nfsmount</span>
                            <img onClick={() => { copyHander(copy1) }} src={copyIcon} alt="" />
                        </div>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy2}>mount -t nfs -o tcp,nolock,nfsvers=3 DNS:/mnt/yrfs /nfsmount</span>
                            <img onClick={() => { copyHander(copy2) }} src={copyIcon} alt="" />
                        </div>
                        <span>参数备注：</span>
                        <span>DNS为步骤一负载均衡中设置的域名</span>
                        <span>/mnt/yrfs为安装存储安装定义的mount_path</span>
                    </div>
                    <p className="client-title-h2">权限控制</p>
                    <div className="client-text-1">
                        <span>对于存储系统提供的NFS共享文件系统，通过将其提供给指定的客户端IP实现访问权限的管理和控制。客户端需要加入LDAP域或者采用存储本地用户组，通过配合管理LDAP域服务器或本地用户组（用户信息、群组信息等）进行权限的管理。</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NFCConfig;