import { useState, useEffect, useCallback } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { Form, Input, Button, Checkbox } from 'antd';
import { PasswordIcon, EmailIcon, VerifyIcon, PhoneIcon } from '../../../components/IconAntd';
import { signUp, smsRegistrar } from "./../../../api/pages/login"
import { encrypt } from "./../../../utils"
import "./../../../styles/pages/login/signUpFrom.less"
interface submitValue {
    email: string,
    password: string,
    phone: string,
    verify: string,
}

const SignInFrom = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [remaining, updateRemaining] = useState(60);
    const [isChecked, updateIsChecked] = useState(false);
    useEffect(() => {
        switch (remaining) {
            case 0:
                updateRemaining(60);
                break;
            case 60:
                break;
            default:
                setTimeout(() => {
                    updateRemaining((remaining: number) => remaining - 1);
                }, 1000);
                break;
        }
    }, [remaining]);
    const onUpdateIsChecked = useCallback((value) => {
        updateIsChecked(value)
    }, [])
    const submit = (values: submitValue) => {
        console.log(values)
        values.password = encrypt(values.password)
        signUp(values).then(() => {
            navigate(`/activation?email=${encodeURIComponent(values.email)}&isActivatePage=1`)
        })
    };
    const sendSms = () => {
        form.validateFields(['phone'])
            .then(values => {
                smsRegistrar(values).then(() => {
                    updateRemaining(59);
                })
            })
            .catch(info => {
                console.log('validate error: ', info);
            });
    };

    return (
        <Form
            form={form}
            onFinish={(values) => submit(values)}
        >
            <Form.Item
                name="email"
                rules={[
                    { required: true, message: '请输入邮箱' },
                    { type: 'email', message: '请输入正确的邮箱' },
                ]}
            >
                <Input placeholder="请输入邮箱" prefix={<EmailIcon />} />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
            >
                <Input.Password placeholder="请输入密码" prefix={<PasswordIcon />} />
            </Form.Item>
            <Form.Item
                name="repassword"
                rules={[
                    { required: true, message: '请再次输入密码' },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('两次输入的密码不一致');
                        }
                    })
                ]}
            >
                <Input.Password placeholder="确认密码" prefix={<PasswordIcon />} />
            </Form.Item>
            <Form.Item
                name="phone"
                rules={[
                    () => ({
                        validator(rule, value) {
                            const pattern = /^1[3-9]\d{9}$/;
                            if (pattern.test(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject('请输入正确的手机号');
                        },
                    }),
                ]}
            >
                <Input placeholder="手机号" prefix={<PhoneIcon />} />
            </Form.Item>
            <Form.Item
                name="verify"
                rules={[{ required: true, message: '请输入验证码' }]}
                className="verify-item"
            >
                <Input addonAfter={<Button
                    onClick={() => sendSms()}
                    disabled={remaining !== 60}
                >
                    {remaining === 60 ? '发送验证码' : `${remaining}秒后重新发送`}
                </Button>} placeholder="验证码" prefix={<VerifyIcon />} />
            </Form.Item>
            <Checkbox onChange={(e) => onUpdateIsChecked(e.target.checked)}>
                我已阅读并同意<Link to="/terms" target="_blank">&nbsp;&nbsp;用户服务协议</Link>
            </Checkbox>
            <br />
            <Button
                className="btn-form"
                type="primary"
                disabled={!isChecked}
                onClick={() => form.submit()}
                style={{ marginTop: 12 }}>立即注册</Button>
        </Form>
    )
}
export default SignInFrom