import Icon from '@ant-design/icons';
import passwordSvg from "./../../assets/imgs/icon_password_16.svg";
import usernameSvg from './../../assets/imgs/icon_account_number_16.svg';
import verifySvg from './../../assets/imgs/icon_verification_code_16.svg';
import emailSvg from './../../assets/imgs/icon_mailbox_16.svg';
import phoneSvg from './../../assets/imgs/icon_mobile_phone_16.svg';

export const PhoneIcon = (props: any) => {
    return <Icon component={() => <img src={phoneSvg} alt="" />} {...props} />;
}
export const VerifyIcon = (props: any) => {
    return <Icon component={() => <img src={verifySvg} alt="" />} {...props} />;
}
export const EmailIcon = (props: any) => {
    return <Icon component={() => <img src={emailSvg} alt="" />} {...props} />;
}
export const UsernameIcon = (props: any) => {
    return <Icon component={() => <img src={usernameSvg} alt="" />} {...props} />;
}
export const PasswordIcon = (props: any) => {
    return <Icon component={() => <img src={passwordSvg} alt="" />} {...props} />;
}
