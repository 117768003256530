import { useState } from 'react'
import commaLeft from "./../../assets/imgs/icon_yinhao_left_48.svg"
import commaRight from "./../../assets/imgs/icon_yinhao_right_48.svg"
import liIcon from "./../../assets/imgs/img_biaoqian_16.svg"
import SignInFrom from './component/SignInFrom';
import SignUpFrom from './component/SignUpFrom';
import "./../../styles/pages/login/index.less"
import ResetPassword from './component/resetPassword';


const Login = () => {

    const [isRegister, setIsRegister] = useState<boolean>(false) // 注册
    const [isForget, setIsForget] = useState<boolean>(false) // 忘记密码

    const setIsForgetFun = (val: boolean) => {
        setIsForget(val)
        setIsRegister(false)
    }
    const setSigninFun = (val: boolean) => {
        setIsForget(val)
        setIsRegister(val)
    }

    return (
        <div className="login">
            <div className="login-box">
                <div className="login-box-left">
                    <div className="left-title">
                        <p>免费试用 YRCloudFile</p>
                        <img src={commaLeft} alt="" />
                        <img src={commaRight} alt="" />
                    </div>
                    <div className="left-text-middle">
                        {/* <span>YRCloudFile是焱融科技自主研发的分布式文件系统，</span> */}
                        {/* <span> 为人工智能（AI）、高性能计算（HPC）、容器编排等场景提供高性能、高可用的文件访问接口和持久化存储空间。</span> */}
                    </div>
                    <ul>
                        <li>今天注册，即可立即使用 YRCloudFile GitHub社区版，为您提供：</li>
                        <li>
                            <img src={liIcon} alt="" />
                            <span>一键化安装</span>
                        </li>
                        <li>
                            <img src={liIcon} alt="" />
                            <span>物理机、虚拟机、云环境等多种部署支持</span>
                        </li>
                        <li>
                            <img src={liIcon} alt="" />
                            <span>50TiB 容量的永久使用权限</span>
                        </li>
                        <li>
                            <img src={liIcon} alt="" />
                            <span>POSIX、NFS、CSI 多种接入方式</span>
                        </li>
                        {/* <li>
                            <img src={liIcon} alt="" />
                            <span>容量以及性能均衡的超高性价比</span>
                        </li> */}
                    </ul>

                </div>
                <div className="login-box-right">
                    <div className="login-right-form">
                        <p className="login-tip">
                            {isRegister ? '欢迎注册焱融账号' : isForget ? '重置密码' : '登录'}
                        </p>
                        <>
                            {isRegister ?
                                // 注册登陆
                                <div className="register-from">
                                    <SignUpFrom />
                                </div>
                                :
                                isForget ?
                                    // 忘记密码
                                    <div>
                                        <ResetPassword setForget={setIsForgetFun} />
                                    </div>
                                    :
                                    // 登陆
                                    <div className="login-from">
                                        <SignInFrom />
                                    </div>
                            }
                        </>
                    </div>

                    <div className="login-footer">
                        <div className="login-foo-acc">
                            <span>{isRegister || isForget ? '已有账号？' : '没有账号？'}</span>
                            {
                                isRegister || isForget ?
                                    <b onClick={() => setSigninFun(false)}>立即登录</b>
                                    :
                                    <b onClick={() => setIsRegister(true)}>立即注册</b>
                            }
                        </div>
                        {
                            !isRegister && !isForget ?
                                <span onClick={() => setIsForget(true)} className="login-foo-forget">忘记密码？</span>
                                :
                                null
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login