import HeaderTitle from "../../components/HeaderTitle";
import "./../../styles/pages/upgrade/index.less"

// 升级到企业版
const ToEnterprise = () => {
    return (
        <div>
            <HeaderTitle />
            <div className="enterprise-title">
                <p>升级至企业版</p>
                <span>YRCloudFileGitHub社区版可以升级至企业版，升级过程会有业务中断，需要规划停机窗口。详情请联系焱融科技销售400-090-8865。</span>
            </div>
            <div className="enterprise-title">
                <p>升级服务</p>
                <span>YRCloudFileGitHub社区版本没有任何安装及运维服务，如需服务，请联系焱融科技销售400-090-8865。</span>
            </div>
        </div>
    )
}
export default ToEnterprise