import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { showMessage } from "./status"
import { useNavigate } from 'react-router-dom'
// import qs from 'qs';
import { message } from "antd";


// 创建axios
export const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 10000, // 超时时间
    method: 'get',
    headers: {
        "Accept": "*/*",
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // 处理form-data 格式
    // transformRequest: [
    //     (data) => {
    //         data = qs.stringify(data);
    //         return data;
    //     }
    // ]
})

// axios 请求拦截器
axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        if (!config?.headers) {
            config.headers = {}
        }
        config.headers.Authorization = `token`; // 请求借口带token
        return config
    },
    (error: any) => {
        return Promise.reject(error)
    }
)

// axios 响应拦截器
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        console.log('--',response)
        const { data } = response
        return data
    },
    (error: any) => {
        const navigate = useNavigate()
       
        const { response } = error;
        console.log(error)
        if(response.status === '401'){
            navigate('/login');
            return ''
        }
        if (error.message.includes('timeout')) {
            message.error('请求超时，请重试');
            return Promise.reject(error)
        }
        if (!navigator.onLine) {
            message.error('网络已断开，请连接后重试');
            return Promise.reject(error)
        }
        if (response.data && response.data.message) {
            // 请求已发出，但是不在2xx的范围
            // message.error(showMessage(response.status));
            message.error(response.data.message)
            return Promise.reject(response.data);
        } else {
            message.error(showMessage(response.status));
            return Promise.reject(response.data);
        }
    }
)

export default axiosInstance
