import "./../../styles/pages/installPlan/index.less"

// table 数据
const tableData = [
    [
        'CPU',
        ['物理机：单CPU物理核数大于4', '虚拟机：vCPU',],
        ['1-2', '8']
    ],
    [
        '内存',
        ['物理机：DDR4 企业级内存', '虚拟机'],
        ['64GB', '32G']
    ],
    [
        '系统盘',
        ['物理机：SSD 企业级磁盘', '虚拟机 SSD盘'],
        ['240GB/480GB', '100GB']
    ],
    [
        '元数据盘',
        'SSD/NVMe 企业级磁盘',
        ['≥ 100GB（200G元数据盘可存储1亿数据）','总数量为双数'],
    ],
    [
        '缓存盘',
        'SSD/NVMe 企业级磁盘',
        '0/1块',
    ],
    [
        '数据盘',
        'SSD/NVMe/SAS/NL SAS/SATA 企业级磁盘',
        '总数量为双数',
    ],
    [
        '网络',
        '1 /10 Gbps TCP',
        '1',
    ]
]
// 服务器最低配置要求
const PlanClaim = () => {
    return (
        <div className="claim">
            <p>X86服务器单台配置（物理机/虚拟机），服务器数量：3</p>
            <div className="claim-table">
                {tableData.map((item, index) => {
                    return (
                        <div key={index} className="claim-table-tr">
                            {item.map((subItem, subIndex) => {
                                return <div key={`${index}-${subIndex}`} className="claim-table-td">
                                    {Array.isArray(subItem) ?
                                        <>
                                            {subItem.map((text, textIndex) => {
                                                return  <p key={`${index}-${subIndex}-${textIndex}`}>{text}</p>
                                            })}
                                        </>
                                        :
                                        <p>{subItem}</p>
                                    }
                                </div>
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default PlanClaim;