import { useRef } from "react"
import { copyHander } from './../../utils';
import copyIcon from "./../../assets/imgs/icon_copy_16.svg"
import "./../../styles/pages/license/index.less"
import License from "./askLicense"

interface RefObject<T> {
    readonly current: T | null
}
// 申请激活许可
const ActivatePermit = () => {
    const copy1: RefObject<HTMLDivElement> = useRef(null)
    const copy2: RefObject<HTMLDivElement> = useRef(null)
    return (
        <div className="license-permit">
            <div className="license-permit-box">
                <p>1、 登陆安装节点，生成license request 文件 并下载 /tmp/req-license.yr</p>
                <div className="sh-copy">
                    <span>[root@node1 ~]# </span>
                    <span ref={copy1}>chika-cli req-license</span>
                    <img onClick={() => { copyHander(copy1) }} src={copyIcon} alt="" />
                </div>
                <p>2、 在网站申请license，上传req-license文件，获取license文件</p>
                <div className="license-permit-box-license">
                <License/>
                </div>
                <p>3、 在安装节点上，上传license</p>
                <div className="sh-copy">
                    <span>[root@node1 ~]# </span>
                    <span ref={copy2}>chika-cli set-license /root/req_license.yr.lic</span>
                    <img onClick={() => { copyHander(copy2) }} src={copyIcon} alt="" />
                </div>
            </div>
        </div>
    )
}
export default ActivatePermit;