import TouchDown from "../../components/TouchDown";
import "./../../styles/pages/installConfig/index.less"

// 下载存储安装包
const SavePackage = () => {
    return (
        <div className="config-fix">
            <div className="config-fix-box">
                <TouchDown text='YRCloudFile GitHub社区版 ISO' loadUrl='http://download.yanrongyun.com/latest/' />
            </div>
        </div>
    )
}
export default SavePackage;