import TouchDown from "../../components/TouchDown"

// ISO下载说明
const IosDownload = () => {
    return (
        <div className="ios-explain">
            <span>YRCloudFile存储安装包：</span>
            <TouchDown text='YRCloudFile GitHub社区版 ISO' loadUrl='http://download.yanrongyun.com/latest/' />
            <div className="ios-explain-list">
                <span>YRCloudFile 客户端安装包：</span>
                <div className="ios-list-ul">
                    <TouchDown text='CentOS 客户端安装包' loadUrl='http://download.yanrongyun.com/latest/clients/centos/' />
                    <TouchDown text='Ubuntu 客户端安装包' loadUrl='http://download.yanrongyun.com/latest/clients/ubuntu' />
                    <TouchDown text='CSI安装包（支持Kubernetes1.13-1.19）' loadUrl='http://download.yanrongyun.com/latest/k8s/' />
                    <TouchDown text='Winclient安装包' loadUrl='http://download.yanrongyun.com/latest/clients/windows/' />
                </div>
                <span>注：客户端版本详细以实际下载目录为准。</span>
            </div>
        </div>
    )
}
export default IosDownload