import type { RouteObject } from "react-router-dom"
import { useRoutes } from "react-router-dom"
import Layout from "../components/Layout"
import PlanClaim from "../pages/installPlan/claim"
import IosDownload from "../pages/installPlan/iosDownload"
import StoreExplain from "../pages/installPlan/storeExplain"
import SaveConfig from "../pages/installConfig/saveConfig"
import SavePackage from "../pages/installConfig/savePackage"
import SystemConfig from "../pages/installConfig/systemConfig"
import ActivatePermit from "../pages/license/activatePermit"
// import AskLicense from "../pages/license/askLicense"
import ClientPackage from "../pages/installClient/clientPackage"
import Dilatation from "../pages/upgrade/dilatation"
import FeedBack from "../pages/upgrade/feedback"
import ToEnterprise from "../pages/upgrade/enterprise"
import Exprie from "../pages/upgrade/expare"
import Login from "../pages/login"
import Error404 from "../pages/404"
import ActiveContainer from "../pages/login/component/activation"
import { TermsContainer } from "../pages/login/component/term"
import LinuxConfig from "../pages/installClient/linuxConfig"
import WinclientConfig from "../pages/installClient/winclientConfig"
import CSIConfig from "../pages/installClient/cslConfig"
import NFCConfig from "../pages/installClient/nfcConfig"

export interface RouterObj extends RouteObject {
    name?: string,
    children?: RouterObj[]
}
export const routes: RouterObj[] = [
    {
        path: '/',
        element: <Layout />,
        children: [
            { index: true, element: <PlanClaim /> },
            {
                path: '/plan',
                name: '安装准备',
                children: [
                    {
                        path: 'claim',
                        name: '服务器最低配置要求',
                        element: <PlanClaim />,
                    },
                    {
                        path: 'storeExplain',
                        name: '存储架构说明',
                        element: <StoreExplain />,
                    },
                    {
                        path: 'iosDownload',
                        name: 'ISO下载说明',
                        element: <IosDownload />,
                    },
                ]
            },
            {
                path: '/config',
                name: '安装配置',
                children: [
                    {
                        path: 'savePackage',
                        name: '下载存储安装包',
                        element: <SavePackage />,
                    },
                    {
                        path: 'systemConfig',
                        name: '安装操作系统配置网络',
                        element: <SystemConfig />,
                    },
                    {
                        path: 'saveConfig',
                        name: '配置存储',
                        element: <SaveConfig />,
                    },
                ]
            },
            {
                path: '/license',
                name: 'License激活',
                children: [
                    // {
                    //     path: 'askLicense',
                    //     name: '申请license',
                    //     element: <AskLicense />,
                    // },
                    {
                        path: 'activatePermit',
                        name: '申请激活许可',
                        element: <ActivatePermit />,
                    },
                ]
            },
            {
                path: '/client',
                name: '安装客户端',
                children: [
                    {
                        path: 'clientPackage',
                        name: '下载客户安装包',
                        element: <ClientPackage />,
                    },
                    {
                        path: 'linuxConfig',
                        name: 'Linux安装配置文档',
                        element: <LinuxConfig />,
                    },
                    {
                        path: 'winclientConfig',
                        name: 'Winclient安装配置文档',
                        element: <WinclientConfig />,
                    },
                    {
                        path: 'cslConfig',
                        name: 'CSI安装配置文档',
                        element: <CSIConfig />,
                    },
                    {
                        path: 'nfcConfig',
                        name: 'NFS配置文档',
                        element: <NFCConfig />,
                    },
                ]
            },
            {
                path: '/upgrade',
                name: 'Q&A',
                children: [
                    {
                        path: 'expare',
                        name: 'License到期',
                        element: <Exprie />,
                    },
                    {
                        path: 'enterprise',
                        name: '升级',
                        element: <ToEnterprise />,
                    },
                    {
                        path: 'dilatation',
                        name: '扩容',
                        element: <Dilatation />,
                    },
                    {
                        path: 'feedback',
                        name: '使用问题反馈',
                        element: <FeedBack />,
                    },
                ]
            },
        ]
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/activation',
        element: <ActiveContainer />
    },
    {
        path: '/activation/:code',
        element: <ActiveContainer />
    },
    {
        path: '/terms',
        element: <TermsContainer />
    },
    {
        path: '/*',
        element: <Error404 />
    }
];
// 导出routes路由器
const RouterView = () => useRoutes(routes)
export default RouterView