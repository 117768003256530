import { useRef } from "react"
import { copyHander } from './../../utils';
import img8 from "./../../assets/imgs/src8.png"
import img9 from "./../../assets/imgs/src9.png"
import copyIcon from "./../../assets/imgs/icon_copy_16.svg"
import "./../../styles/pages/instaliClient/index.less"


interface RefObject<T> {
    readonly current: T | null
}
// CSI安装及配置客户端
const CSIConfig = () => {
    const copy1: RefObject<HTMLDivElement> = useRef(null)
    const copy2: RefObject<HTMLDivElement> = useRef(null)
    const copy3: RefObject<HTMLDivElement> = useRef(null)
    const copy4: RefObject<HTMLDivElement> = useRef(null)
    const copy5: RefObject<HTMLDivElement> = useRef(null)
    const copy6: RefObject<HTMLDivElement> = useRef(null)
    const copy7: RefObject<HTMLDivElement> = useRef(null)
    const copy8: RefObject<HTMLDivElement> = useRef(null)
    const copy9: RefObject<HTMLDivElement> = useRef(null)
    return (
        <div className="config-client">
            <div className="config-client-box">
                <div className="client-title">
                    <p className="client-title-h1">CSI安装配置文档</p>
                    <p className="client-title-h2">存储上创建共享目录</p>
                    <div className="client-text-1">
                        <span>1. </span>
                        <img className="src-img" src={img8} alt="" />
                        <span>2. </span>
                        <img className="src-img" src={img9} alt="" />
                        <span>3. ssh到安装节点，执行添加k8s集群权限</span>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy1}>yrcli --cliacl --op=add --ip=*</span>
                            <img onClick={() => { copyHander(copy1) }} src={copyIcon} alt="" />
                        </div>
                    </div>
                    <p className="client-title-h2">安装csi驱动</p>
                    <div className="client-text-1">
                        <span>1. 在所有节点上加载镜像文件</span>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy1}>docker load -i allinone.tar</span>
                            <img onClick={() => { copyHander(copy1) }} src={copyIcon} alt="" />
                        </div>
                        <span>2. 在存储端加载Kubernetes集群ko文件</span>
                        <span>需要将 k8s所有节点操作系统内核对应的 ko文件（$(uname -r).ko），放置在集群3节点的 /var/www/html/files 目录内。具体ko文件在上一级的对应kos目录内。</span>
                        <span>3. 在master节点执行HELM 安装</span>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy2}>curl -O https://get.helm.sh/helm-v3.6.3-linux-amd64.tar.gz</span>
                            <img onClick={() => { copyHander(copy2) }} src={copyIcon} alt="" />
                        </div>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy3}>tar -xf helm-v3.6.3-linux-amd64.tar.gz</span>
                            <img onClick={() => { copyHander(copy3) }} src={copyIcon} alt="" />
                        </div>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy4}>mv linux-amd64/helm /usr/local/sbin/</span>
                            <img onClick={() => { copyHander(copy4) }} src={copyIcon} alt="" />
                        </div>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy5}>chmod +x /usr/local/sbin/helm</span>
                            <img onClick={() => { copyHander(copy5) }} src={copyIcon} alt="" />
                        </div>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy6}>tar -zxf yrfs-chart.tar.gz</span>
                            <img onClick={() => { copyHander(copy6) }} src={copyIcon} alt="" />
                        </div>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy7}>cd yrfs-csi-helm-chart</span>
                            <img onClick={() => { copyHander(copy7) }} src={copyIcon} alt="" />
                        </div>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy8}>helm install release --create-namespace --namespace yanrongyun --set</span>
                            <img onClick={() => { copyHander(copy8) }} src={copyIcon} alt="" />
                        </div>
                        <span>yrcf.configEndpoint=http://{'{'}安装节点IP{'}'}:8080 --set yrcf.storageClass.default=true .</span>
                        <span>4. 创建YRCloudFile-CSI POD</span>
                        <span>修改 yrfs-csi.yaml内的192.168.30.12的IP（6处）为对应的集群的安装IP地址Yaml文件内 CLUSTER属性，以及StorageClass内相应的annotation要定义为一致，为存储内/kubernetes下的目录名字</span>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy9}>kubectl apply -f yrfs-csi.yaml</span>
                            <img onClick={() => { copyHander(copy9) }} src={copyIcon} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CSIConfig;