import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { emailActive, activateAccount } from "./../../../api/pages/login"
import { useSearchParams, Link, useNavigate, useParams } from "react-router-dom"
import "./../../../styles/pages/login/activation.less"


const ActiveContainer = () => {
    const [, setTimerID] = useState(null);
    let navigate = useNavigate();
    const [remaining, updateRemaining] = useState(60);
    const [toLogin, setToLogin] = useState(10);
    const [searchParams] = useSearchParams()
    const { code } = useParams()
    // 如果isActivatePage 不存在 判断code是否存在 存在设置isActivatePage=2 为账户激活成功！
    //isActivatePage 「0:激活账户 ,1:账户注册成功！,2:账户激活成功！」
    const isActivatePage = Number(searchParams.get('isActivatePage')) || Number(code ? 2 : 0);
    const email = searchParams.get('email');
    const activeTipArr: string[] = ['激活账户', '账户注册成功！', '账户激活成功！']
    // 60s
    useEffect(() => {
        switch (remaining) {
            case 0:
                updateRemaining(60);
                break;
            case 60:
                break;
            default:
                setTimeout(() => {
                    updateRemaining((remaining: number) => remaining - 1);
                }, 1000);
                break;
        };
    }, [remaining]);

    // 10s
    useEffect(() => {
        if (isActivatePage === 1) {
            // 注册成功
            sendActivateEmail()
        } else if (isActivatePage === 2) {
            if (toLogin > 0) {
                let timer: any = setTimeout(() => {
                    setToLogin(toLogin - 1)
                }, 1000);
                setTimerID(timer)
            } else {
                navigate('/login')
            }
            return () => {
                setTimerID(null)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toLogin])
    useEffect(() => {
        if (isActivatePage === 2) {
            activateAccount({ code }).catch(() => {
                navigate(`/login`)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const sendActivateEmail = async () => {
        updateRemaining(59);
        await emailActive({ email })
    };

    return (
        <div className="acitve-box">
            <div className={`active-top-img ${isActivatePage ? 'isActive' : 'isSuccess'}`}></div>
            <p className="acitve-content">{activeTipArr[isActivatePage - 1]}</p>
            <p className="acitve-content-tip">{isActivatePage === 2 ? `${toLogin}s后跳转至登录页` : '请登录您的注册邮箱，根据邮箱激活该账户'}</p>
            <p className="active-content-login"></p>
            {
                isActivatePage !== 2 ?
                    <Button
                        disabled={remaining !== 60}
                        onClick={() => sendActivateEmail()}
                        type="primary"
                        className="success-btn"
                    >
                        {remaining === 60 ? '' : `(${remaining}s)`}重新发送激活邮件
                    </Button>
                    :
                    <Button className="success-btn">
                        <Link to="/login">去登陆</Link>
                    </Button>
            }

        </div>
    )
}
export default ActiveContainer