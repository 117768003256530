import { useRef } from "react"
import { copyHander } from './../../utils';
import copyIcon from "./../../assets/imgs/icon_copy_16.svg"
import "./../../styles/pages/instaliClient/index.less"
import img1 from "./../../assets/imgs/src1.png"
import img2 from "./../../assets/imgs/src2.png"

interface RefObject<T> {
    readonly current: T | null
}
// Linux安装及配置客户端
const LinuxConfig = () => {
    const copy1: RefObject<HTMLDivElement> = useRef(null)
    const copy1_1: RefObject<HTMLDivElement> = useRef(null)
    const copy2: RefObject<HTMLDivElement> = useRef(null)
    const copy3: RefObject<HTMLDivElement> = useRef(null)
    // const copy4: RefObject<HTMLDivElement> = useRef(null)
    // const copy5: RefObject<HTMLDivElement> = useRef(null)
    const copy6: RefObject<HTMLDivElement> = useRef(null)
    const copy7: RefObject<HTMLDivElement> = useRef(null)
    const copy8: RefObject<HTMLDivElement> = useRef(null)
    return (
        <div className="config-client">
            {/* <HeaderTitle /> */}
            <div className="config-client-box">
                <div className="client-title">
                    <p className="client-title-h1">Linux安装配置文档（CentOS\Ubuntu）</p>
                    <p className="client-title-h2">安装客户端</p>
                    <div className="client-text-1">
                        <span>1. 登陆存储管理界面（http://安装节点IP），创建挂载目录，添加目录共享</span>
                        <img className="src-img" src={img1} alt="" />
                        <img className="src-img" src={img2} alt="" />
                        <span>2. 下载安装客户端安装包</span>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy1}>yum install xxxxxx</span>
                            <img onClick={() => { copyHander(copy1) }} src={copyIcon} alt="" />
                            <span>#CentOS</span>
                        </div>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy1_1}>sudo dpkg -i *.deb</span>
                            <img onClick={() => { copyHander(copy1_1) }} src={copyIcon} alt="" />
                            <span>#ubuntu</span>
                        </div>
                    </div>
                    <p className="client-title-h2">配置客户端</p>
                    <div className="client-text-1">
                        <span>3. 配置网络</span>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy2}>echo "XX.XX.XX.XX/XX" {'>'} /etc/yrfs/net</span>
                            <img onClick={() => { copyHander(copy2) }} src={copyIcon} alt="" />
                            <span>(存储网段CIDR)</span>
                        </div>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy3}>echo "eth0" {'>'} /etc/yrfs/interface</span>
                            <img onClick={() => { copyHander(copy3) }} src={copyIcon} alt="" />
                            <span>eth0为网卡，根据实际情况</span>
                        </div>
                        {/* <div className="sh-copy">
                            <span># </span>
                            <span ref={copy4}>sed -i '$a\conn_nics_file     = /etc/yrfs/interface' /etc/yrfs/yrfs-client.conf</span>
                            <img onClick={() => { copyHander(copy4) }} src={copyIcon} alt="" />
                            <span>设置客户端访问网卡</span>
                        </div> */}
                        {/* <div className="sh-copy">
                            <span># </span>
                            <span ref={copy5}>sed -i '$a\conn_subnet_filter_file      = /etc/yrfs/net' /etc/yrfs/yrfs-client.conf</span>
                            <img onClick={() => { copyHander(copy5) }} src={copyIcon} alt="" />
                            <span>设置客户端访问存储网络</span>
                        </div> */}
                        <span>4. 编辑yrfs-client.conf配置文件，增加3个节点的ip</span>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy6}>vim /etc/yrfs/yrfs-client.conf</span>
                            <img onClick={() => { copyHander(copy6) }} src={copyIcon} alt="" />
                        </div>
                        <span>cluster_addr= xx.xx.xx.xx, xx.xx.xx.xx, xx.xx.xx.xx</span>
                        <span>5. 编辑mounts.conf添加挂载目录信息及挂载秘钥</span>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy7}>vim /etc/yrfs/yrfs-mounts.conf</span>
                            <img onClick={() => { copyHander(copy7) }} src={copyIcon} alt="" />
                        </div>
                        <span>/mnt/yrfs（客户端挂载目录） /etc/yrfs/yrfs-client.conf /linuxclient（子目录）</span>
                        <span>6. 启动客户端服务，启动挂载</span>
                        <div className="sh-copy">
                            <span># </span>
                            <span ref={copy8}>systemctl start yrfs-client</span>
                            <img onClick={() => { copyHander(copy8) }} src={copyIcon} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LinuxConfig;