import service from './../index'


// get 请求
export function putLogin(params: Record<string, any>) {
    return service({
        url: 'xxxx/xxx/xxx',
        params
    })
}
// 登陆
export function signIn(data: any) {
    return service({
        method: 'POST',
        url: '/api-free/auth/sign-in',
        data
    })
}
// 注册
export function signUp(data: any) {
    return service({
        method: 'POST',
        url: '/api-free/auth/sign-up',
        data
    })
}
// 重置密码
export function resetPassword(data: any) {
    return service({
        method: 'PUT',
        url: '/api-free/auth/reset-password',
        data
    })
}
// 发送注册短信
export function smsRegistrar(data: any) {
    return service({
        method: 'POST',
        url: '/api-free/auth/sms/registrar',
        data
    })
}
// 发送重置密码短信
export function smsReset(data: any) {
    return service({
        method: 'POST',
        url: '/api-free/auth/sms/reset',
        data
    })
}
// 发送激活邮件
export function emailActive(data: any) {
    return service({
        method: 'POST',
        url: '/api-free/auth/email/activation',
        data
    })
}
// 激活账号
export function activateAccount(data: any) {
    return service({
        method: 'POST',
        url: '/api-free/auth/activate-account',
        data
    })
}
// 获取是否需要验证码
export function getCaptcha(params: Record<string, any> = {}) {
    return service({
        method: 'GET',
        url: '/api-free/auth/captcha',
        params
    })
}

