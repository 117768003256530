import { useRef } from "react"
import { copyHander } from './../../utils'
import copyIcon from "./../../assets/imgs/icon_copy_16.svg"
interface RefObject<T> {
    readonly current: T | null
}
// 配置存储
const SaveConfig = () => {
    const refContainer: RefObject<HTMLDivElement> = useRef(null)
    const refContainer2: RefObject<HTMLDivElement> = useRef(null)
    return (
        <div className="config-save">
            <div className="config-save-box">
                <p> 1. ssh登陆任意一个节点</p>
                <p> 2. 编辑安装配置yaml文件</p>
                <div className="sh-copy">
                    <span>[root@node1 ~]# </span>
                    <span ref={refContainer}>vim install.yaml</span>
                    <img onClick={() => { copyHander(refContainer) }} src={copyIcon} alt="" />
                </div>
                <div className="config-table">

                    <div className="config-table-body">
                        <p>hosts:</p>
                        <p>- hostname: node1.yr</p>
                        <div className="config-table-body_div">
                            <p>mds_disks: [/dev/sdb,/dev/sdc]</p>
                            <p>address: 192.168.13.99</p>
                            <p>osd_disks: [/dev/sdd, /dev/sde]</p>
                            <p>password: Passw0rd</p>
                        </div>
                        <p>- hostname: node2.yr</p>
                        <div className="config-table-body_div">
                            <p>mds_disks: [/dev/sdb,/dev/sdc]</p>
                            <p>address: 192.168.13.100</p>
                            <p>osd_disks: [/dev/sdd, /dev/sde]</p>
                            <p>password: Passw0rd</p>
                        </div>
                        <p>- hostname: node3.yr</p>
                        <div className="config-table-body_div">
                            <p>mds_disks: [/dev/sdb,/dev/sdc]</p>
                            <p>address: 192.168.13.101</p>
                            <p>osd_disks: [/dev/sdd, /dev/sde]</p>
                            <p>password: Passw0rd</p>
                        </div>
                        <p>mgmt_cidr: 192.168.0.0/16</p>
                        <p>mount_path: /mnt/yrfs</p>
                    </div>
                </div>
                <p>参数说明：</p>
                <div className="config-tab-div">
                    <p>hostname：为各节点名称</p>
                    <p>mds_disks：元数据盘盘符</p>
                    <p>osd_disks：数据盘盘符</p>
                    <p>带缓存的osd_disks： - /dev/sdd: [/dev/sdf, /dev/sde]</p>
                    <p>address：各节点IP地址</p>
                    <p>mgmt_cidr：管理网IP CIDR</p>
                    <p>mount_path：存储根目录</p>
                    <p>注：osd容量在50TB以内，否则安装失败</p>
                </div>
                <p>3. 执行yaml安装</p>
                <div className="sh-copy">
                    <span>[root@node1 ~]# </span>
                    <span ref={refContainer2}>chika-cli apply install.yaml</span>
                    <img onClick={() => { copyHander(refContainer2) }} src={copyIcon} alt="" />
                </div>
                <p>注：安装完成之后，根据提示，可以登陆管理界面，请保存admin用户名密码！</p>
            </div>
        </div>
    )
}
export default SaveConfig;