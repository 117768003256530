import { Modal, Upload, Form, Input } from 'antd';
import { useState } from 'react';
import { useNavigate } from "react-router-dom"
import { licenseApi } from "./../../api/pages/license"
import "./../../styles/pages/license/index.less"

// 申请license
const AskLicense = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [file, setFile] = useState<any>(null)
    const [fileErr, setFileErr] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const handerClick = () => {
        if (!window.sessionStorage.getItem('freeEmail')) {
            navigate('/login');
        } else {
            form.resetFields()
            setFile(null)
            setFileErr(false)
            setIsModalVisible(true);
            setLoading(false)
        }
    }
    const handleOk = () => {
        form.validateFields().then(() => {
            if(!loading){
                setLoading(true)
                uploadFormData();
            }
        })
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const uploadFormData = () => {
        const formData = new FormData()
        formData.append('file', file);
        licenseApi(formData).then((data: any) => {
            const blob = new Blob([data]);
            const filename = 'yrcloud_file_license.lic';
            const targetElement = document.createElement('a');
            targetElement.download = filename;
            targetElement.style.display = 'none';
            targetElement.href = URL.createObjectURL(blob);
            document.body.appendChild(targetElement);
            targetElement.click();
            URL.revokeObjectURL(targetElement.href);
            document.body.removeChild(targetElement);
            setLoading(false)
            setIsModalVisible(false);
        }).catch(()=>{
            setLoading(false)
        })
    }
    const beforeUpload = (file: any) => {
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (isLt2M) {
            setFile(file)
        } else {
            setFileErr(true)
            form.validateFields()
        }
        return false
    }

    return (
        <div className="license-asx">
            <div className="free-button" onClick={() => handerClick()}>申请License</div>
            <p>注1：集群安装后，需要在12小时候内激活许可，否则集群将不可用</p>
            <p>注2：免费授权周期为30天，授权到期需要重复执行该流程</p>
            <Modal title="申请授权"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                centered={true}
                okText="确认"
                cancelText="取消"
                width="600"
                className="license-body"
            >
                <Form
                    form={form}
                >
                    <Form.Item
                        name="upload"
                        rules={[{ required: true, message: '' }, () => ({
                            validator() {
                                if (!fileErr && file?.name) {
                                    return Promise.resolve();
                                } else if (fileErr) {
                                    return Promise.reject(new Error('授权文件大小不能超过 10MB'));
                                } else {
                                    return Promise.reject(new Error('请上传文件'));
                                }
                            },
                        }),]}
                        className="license-upload"
                    >
                        <div className="license-input-box">
                            <span className="upload-name">授权文件</span>
                            <Input placeholder="请选择文件" value={file ? file?.name : ''} />
                            <Upload
                                className="upload-box"
                                showUploadList={false}
                                accept=".yr"
                                multiple={false}
                                onRemove={() => { setFile(null) }}
                                beforeUpload={beforeUpload}
                            >
                                <span className="uploadBtn" onClick={() => { setFile(null) }}>{file ? '重新文件' : '选择文件'}</span>
                            </Upload>
                        </div>

                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
export default AskLicense;