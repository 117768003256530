
// 安装操作系统配置网络
const SystemConfig = () => {
    return (
        <div className="config-system">
            <div className="config-system-box">
                <span>系统分区推荐配置：</span>
                <div className="config-system-li">
                    <div>
                        <span>/boot</span>
                        <span>1G</span>
                    </div>
                    <div>
                        <span>/ </span>
                        <span>剩余</span>
                    </div>
                </div>
                <span>系统分区格式化参数：</span>
                <p>EXT4</p>
                <span>网络设置：</span>
                <p>配置IPv4，支持bond</p>
            </div>
        </div>
    )
}
export default SystemConfig;