/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Button } from 'antd';
import { useNavigate } from 'react-router';
import { InfoCircleFilled } from '@ant-design/icons';
import { PasswordIcon, UsernameIcon, VerifyIcon } from './../../../components/IconAntd';
import { signIn, getCaptcha } from "./../../../api/pages/login"
import { encrypt } from "./../../../utils"
import "./../../../styles/pages/login/signInFrom.less"

interface submitValue {
    email: string,
    password: string,
}

const SignInFrom = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [isActivated, setIsActivated] = useState(true)
    const [time, updateTime] = useState(Date.now());
    const [captcha, setCaptcha] = useState({ isNeed: false })
    const [fetching, setFetching] = useState(false)
    const [email, updateEmail] = useState<string>('');

    const activate = () => {
        navigate(`/activation?email=${encodeURIComponent(email)}&isActivatePage=1`)
    };
    // 清除登陆信息
    useEffect(() => {
        window.sessionStorage.removeItem('freeEmail');
    }, [])

    const submit = (values: submitValue) => {
        setFetching(false)
        updateEmail(values.email);
        values.password = encrypt(values.password)
        signIn(values).then(() => {
            // 添加登陆信息
            window.sessionStorage.setItem('freeEmail', values.email)
            setFetching(false)
            navigate('/license/activatePermit', { replace: true });
        }).catch(async (error) => {
            setIsActivated(error.isActived === false ? error.isActived : true)
            const { isNeed } = await getCaptcha();
            setCaptcha({ isNeed })
        })
    };


    return (
        <Form
            form={form}
            name="web"
            onFinish={(values) => submit(values)}
            className="sign-in-form"
        >
            <Form.Item name="email" rules={[
                { required: true, message: '请输入邮箱' },
                { type: 'email', message: '请输入正确的邮箱' },
            ]}>
                <Input placeholder="请输入用户名/邮箱" prefix={<UsernameIcon />} />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
                <Input.Password
                    onKeyDown={(e) => e.key === 'Enter' ? form.submit() : ''}
                    placeholder="请输入密码"
                    prefix={<PasswordIcon />}
                />
            </Form.Item>
            {captcha?.isNeed ? <Row>
                <Col span={15}>
                    <Form.Item name="code">
                        <Input placeholder="请输入验证码" prefix={<VerifyIcon />} />
                    </Form.Item>
                </Col>
                <Col span={8} offset={1}>
                    <div className="verify-box">
                        <img
                            onKeyDown={(e) => e.key === 'Enter' ? form.submit() : ''}
                            onClick={() => updateTime(Date.now())}
                            src={`/api-free/captcha?time=${time}`}
                            alt="验证码"
                        />
                    </div>
                </Col>
            </Row> : null}
            {isActivated ? null : <div className="go-activate">
                <InfoCircleFilled className="activate-icon" />
                <span>您的账户未激活，点击<a onClick={activate}>激活</a></span>
            </div>}
            <Button type="primary" className="btn-form" onClick={() => form.submit()} loading={fetching}>登录</Button>
        </Form>
    )
}
export default SignInFrom