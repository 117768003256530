import TouchDown from "../../components/TouchDown";
import "./../../styles/pages/instaliClient/index.less"

// 下载客户安装包
const ClientPackage = () => {
    return (
        <div className="pack-client">
            <ul className="pack-client-ul">
                <li>
                    <TouchDown text='CentOS 客户端安装包' loadUrl='http://download.yanrongyun.com/latest/clients/centos/' />
                </li>
                <li>
                    <TouchDown text='Ubuntu 客户端安装包' loadUrl='http://download.yanrongyun.com/latest/clients/ubuntu' />
                </li>
                <li>
                    <TouchDown text='CSI安装包（支持Kubernetes1.13-1.19）' loadUrl='http://download.yanrongyun.com/latest/k8s/' />
                </li>
                <li>
                    <TouchDown text='Winclient安装包' loadUrl='http://download.yanrongyun.com/latest/clients/windows/' />
                </li>
            </ul>
        </div>
    )
}
export default ClientPackage;