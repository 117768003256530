import downIcon from "./../../assets/imgs/icon_installation_package_16.svg"
import "./index.less"

interface IProps {
    text?: string,
    loadUrl?: string
}
const TouchDown = (props: IProps) => {
    const loadFile = () => {
        window.open(props.loadUrl, '_blank');
    }
    return (
        <div>
            <div onClick={loadFile} className="touch-down">
                <img src={downIcon} alt={props.text} />
                <b>{props.text}</b>
            </div>
        </div>
    )
}
export default TouchDown