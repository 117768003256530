import './index.less'

interface Iprops {
    children: string | number,
    isActive: boolean
}
const Icon = (props: Iprops) => {
    return (
        <div className="menu-icon-box">
            <div className={`meun-icon ${props.isActive && 'is-active'}`}>
                <div className={`meun-icon-item ${props.isActive && 'is-active'}`}>
                    {Number(props.children) + 1}
                </div>
            </div>
        </div>
    )
}
export default Icon