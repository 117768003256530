import { message } from 'antd';
import ReactDOM from 'react-dom';
import App from './App';
import "antd/dist/antd.less"
import "./styles/global.less"

message.config({
  maxCount: 1, // 最大显示数, 超过限制时，最早的消息会被自动关闭
})
ReactDOM.render(
  <App />,
  document.getElementById('root')
);
