import { useLocation } from "react-router-dom"
import "./index.less"

const RouterPath = {
    // 默认路径
    default: '服务器最低配置要求',
    // 安装准备
    claim: '服务器最低配置要求',
    iosDownload: 'ISO下载说明',
    storeExplain: '存储架构说明',
    // 安装配置
    savePackage: '下载存储安装包',
    systemConfig: '安装操作系统配置网络',
    saveConfig: '配置存储',
    // License激活
    askLicense: '申请license',
    activatePermit: '申请激活许可',
    // 客户端安装
    clientPackage: '下载客户安装包',
    linuxConfig: 'Linux安装配置文档',
    winclientConfig: 'Winclient安装配置文档',
    cslConfig: 'CSI安装配置文档',
    nfcConfig: 'NFS配置文档',
    // 升级
    expare: 'License到期',
    enterprise: '升级',
    dilatation: '扩容',
    feedback: '使用问题反馈',
}

const HeaderTitle = () => {
    const url: string = useLocation().pathname.split('/')[2] || 'default';
    return (
        <div className='navTitle' >
            {RouterPath[url]}
        </div>
    )
}
export default HeaderTitle