/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { Form, Input, Button, message as Message } from "antd";
import { PasswordIcon, UsernameIcon, VerifyIcon } from './../../../components/IconAntd';
import { encrypt } from "./../../../utils"
import { smsReset, resetPassword } from "./../../../api/pages/login"
import "./../../../styles/pages/login/resetPassword.less"

interface Iprops {
    setForget: Function
}
interface resetValue {
    email: string,
    password: string,
    repassword?: string,
    verify: string,
}

const ResetPassword = (props: Iprops) => {
    const [form] = Form.useForm();
    const [remaining, updateRemaining] = useState(60);

    useEffect(() => {
        switch (remaining) {
            case 0:
                updateRemaining(60);
                break;
            case 60:
                break;
            default:
                setTimeout(() => {
                    updateRemaining((remaining: number) => remaining - 1)
                }, 1000);
                break;
        };
    }, [remaining]);

    const submit = (values: resetValue) => {
        delete values.repassword
        values.password = encrypt(values.password);
        resetPassword(values).then(() => {
            Message.success('修改密码成功');
        })
        props.setForget(false)
    };

    const sendSms = () => {
        form.validateFields(['email'])
            .then(values => {
                smsReset(values).then((res) => {
                    updateRemaining(59);
                })
                // dispatch(LoginAction.sendResetSms(values.email));
            })
            .catch(info => {
                console.log('validate error: ', info);
            });
    };
    return (
        <Form
            form={form}
            onFinish={(values) => submit(values)}
            className="reset-password-form"
        >
            <Form.Item
                name="email"
                rules={[
                    { required: true, message: '请输入邮箱' },
                    { type: 'email', message: '请输入正确的邮箱' },
                ]}
            >
                <Input placeholder="请输入账号/邮箱" prefix={<UsernameIcon />} />
            </Form.Item>
            <Form.Item
                name="verify"
                rules={[{ required: true, message: '请输入验证码' }]}
                className="verify-item"
            >
                <Input
                    addonAfter={<Button
                        onClick={() => sendSms()}
                        disabled={remaining !== 60}
                    >
                        {remaining === 60 ? '发送验证码' : `${remaining}秒后重新发送`}
                    </Button>}
                    placeholder="验证码"
                    prefix={<VerifyIcon />}
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
            >
                <Input.Password placeholder="请输入密码" prefix={<PasswordIcon />} />
            </Form.Item>
            <Form.Item
                name="repassword"
                rules={[
                    { required: true, message: '请再次输入密码' },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('两次输入的密码不一致');
                        }
                    })
                ]}
            >
                <Input.Password placeholder="确认密码" prefix={<PasswordIcon />} />
            </Form.Item>
            <Button type="primary" onClick={() => form.submit()} style={{ marginTop: 24 }} className="btn-form">重置密码</Button>
        </Form>
    )
}
export default ResetPassword