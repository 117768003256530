import { useState, useEffect } from 'react'
import { RouterObj, routes } from "../../router/index"
import { Outlet, Link, useLocation } from "react-router-dom"
import Icon from "../Icon"

import "./index.less"
import HeaderTitle from '../HeaderTitle'

const Layout = () => {
    // 变量区
    const location = useLocation();
    const [path, setPath] = useState(['/', '']);
    let menuList = routes[0].children?.slice(1);
    // 生命区
    useEffect(() => {
        let pathArr = location.pathname.split('/');
        if (!pathArr[1]) {
            setPath(['/plan', 'claim']);
        } else {
            setPath([`/${pathArr[1]}`, pathArr[2]]);
        }
        window.scrollTo(0,0);
    }, [location.pathname]);
    // 方法区
    const menuChild = (list: RouterObj[] = [], parentPatch: string = '/') => {
        return (
            <div className="menu-list-child">
                {list.map((item) => {
                    if (item.children?.length) {
                        return menuChild(item.children, item.path);
                    } else {
                        return <Link key={item.name} className={`menu-title-item ${path[1] === item.path && 'menu-active'}`} to={`${parentPatch}/${item.path}`}>{item.name}</Link>
                    }
                })}
            </div>
        )
    }
    // DOM区
    return (
        <div className="lay-content">
            <div className="menu-list-left">
                {menuList?.map((item, index) => {
                    return (
                        <div key={item.name} className="menu-flex">
                            <Icon isActive={path[0] === item.path}>{index}</Icon>
                            <div className="menu-title">
                                <span className={`muen-title-li ${path[0] === item.path && 'menu-active'}`}>{item.name}</span>
                                {item.children?.length ? menuChild(item.children, item.path) : ''}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="content-view-right">
                <HeaderTitle />
                <div className="content-view-outlet">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Layout
