import service from './../index'

// 登陆
export function licenseApi(data: any) {
    return service({
        method: 'POST',
        url: '/api-free/license',
        data
    })
}
