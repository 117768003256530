import HeaderTitle from "../../components/HeaderTitle";
import "./../../styles/pages/upgrade/index.less"

// License到期
const Exprie = () => {
    return (
        <div className="expare">
            <HeaderTitle/>
            <p className="upgrade-text">GitHub社区版本license期限为1个月，license到期，存储将不可访问。如需继续使用存储，请重复申请license。</p>
        </div>
    )
}
export default Exprie