import img3_1 from "./../../assets/imgs/src3_1.png"
import img3_2 from "./../../assets/imgs/src3_2.png"
import img3 from "./../../assets/imgs/src3.png"
import img4 from "./../../assets/imgs/src4.png"
import img5 from "./../../assets/imgs/src5.png"
import img6 from "./../../assets/imgs/src6.png"
import img7 from "./../../assets/imgs/src7.png"
import "./../../styles/pages/instaliClient/index.less"

// Winclient安装及配置客户端
const WinclientConfig = () => {
    return (
        <div className="config-client">
            {/* <HeaderTitle /> */}
            <div className="config-client-box">
                <div className="client-title">
                    <p className="client-title-h1">Winclient安装配置文档</p>
                    <p className="client-title-h2">安装winclient</p>
                    <div className="client-text-1">
                        <span>1. 获取YRCloudFile客户端相关软件包及VC安装包</span>
                        <span>2. 首先在Windows业务主机中安装环境软件包：VC_redist.x64.exe。</span>
                        <span>如遇VC安装失败，请下载并依次安装如下windows系统更新：clearcompressionflag.exe、KB2919355、KB2932046、KB2959977、KB2937592、KB2938439、KB2934018。</span>
                        <span>3. 然后在Windows业务主机中安装YRCloudFile客户端软件包：WinYRCF-XXXXX.msi</span>
                        <span>4. 在存储端添加导出目录</span>
                        <img className="src-img" src={img3_1} alt="" />
                        <img className="src-img" src={img3_2} alt="" />
                    </div>
                    <p className="client-title-h2">挂载YRCloudFile客户端</p>
                    {/* <p className="client-title-h3">- 修改Windows客户端net文件</p>
                    <div className="client-text-1">
                        <span>编辑net文件，文件路径：｛安装目录｝/cfg/net文件，定义通信的IP地址段（IPv4或者IPv6）</span>
                    </div>
                    <p className="client-title-h3">- 修改Windows客户端winyrcf-client.conf文件</p> */}
                    <div className="client-text-2">
                        <span>1. 修改Windows客户端net文件</span>
                        <span>编辑net文件，文件路径：｛安装目录｝/cfg/net文件，定义通信的IP地址段（IPv4或者IPv6）</span>
                        <img className="src-img" src={img3} alt="" />
                        <span>2. 挂载客户端</span>
                        <span>双击桌面WinYRCF图标，弹出WinYRCF挂载管理器。每个添加一个挂载点，会相应生成一个与挂载名称同名的配置文件xxx.conf，文件存放在｛安装目录｝/cfg目录。手动更改配置文件同样生效。</span>
                        <img className="src-img" src={img4} alt="" />
                        <span>点击添加挂载按钮，弹出挂载点配置文件。依次输入相关信息：</span>
                        <img className="src-img" src={img5} alt="" />
                        <div className="client-text-3">
                            <span>a)挂载名称：支持字母和数字，20字符以内，挂载名称不可重复；</span>
                            <span>注：如果以前安装过的配置文件没有删干净，或者有手动编辑的配置文件，也会按照配置文件名称展示出挂载名称。</span>
                            <span>b)挂载盘符：Windows环境下的盘符，大写字母，盘符不可重复；</span>
                            <span>c)UDP端口：单挂载时，默认8004不需要修改；多挂载时，UDP端口号不能重复，可选8005，以此递增；</span>
                            <span>d)存储管理IP： 存储管理节点的三个IP地址；</span>
                            <span>e)存储挂载目录：存储上设置该客户端允许访问的相关导出目录；</span>
                            <span>f)客户端ID（选填）：客户端IP验证时，不需要修改；IPv6只支持客户端ID验证；</span>
                            <span>g)系统缓存：客户端系统缓存，与ShareAccess功能冲突，不能同时打开；</span>
                            <span>h)应用层缓存： 配置文件中相关参数为：app_cache_enabled_flag ，file_data_cache_enabled_flag。</span>
                            <span>当应用层缓存设置为开启时，需要在安装目录../cfg/下，创建cacheDir文件，把挂载目录（/）设置成on，也可以单独把某个子目录的应用层缓存设置成off。如下图：</span>
                            <img className="src-img" src={img6} alt="" />
                            <span>注1：应用层缓存适用于单客户端独享目录、或多客户端只读共享目录，如存在多客户端读写共享目录，则设置成关闭。</span>
                            <span>注2：应用层缓存，仅支持客户端单挂载，如存在多挂载（多目录挂载，多集群挂载），则设置成关闭。</span>
                            <span>i)ShareAccess： 适用于多客户端并发访问同一个文件， shareAccess就是为了处理，多节点访问同一个文件时来自"其他访问者"的影响。简单的说场景就是多节点同时访问一个文件，期望互斥，就要启用功能</span>
                        </div>
                        <span>3. 多挂载时，继续添加挂载点配置文件即可</span>
                        <span>4. 填写好配置文件，可以直接触发立即挂载，也可以在挂载管理器中选中，挂载。</span>
                        <span>5. 卸载，需要打开挂载管理器，选中挂载点，点击终止。</span>
                        <span>6. 删除，点击删除按钮会删除相关挂载点的配置文件。</span>
                    </div>
                    <p className="client-title-h2">查看客户端磁盘挂载情况</p>
                    <img className="src-img" src={img7} alt="" />
                </div>
            </div>
        </div>
    )
}
export default WinclientConfig;